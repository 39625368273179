.LevelNav {
    width: 200px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to left, var(--menu-bg-color) 50%, var(--header-bg-color) 50%) left bottom;
    background-size: 200% 100%;
    transition: all 0.3s ease-out;
}

.LevelNav p:hover {
    cursor: pointer;
}

.LevelNav a {
    color: var(--menu-bg-color);
    margin-top: 15px;
    font-size: 50px;
}

.LevelNav.open {
    transition: 1s;
    background-position: right bottom;
}
.LevelNav.open a {
    transition: 2s;
    color: var(--header-bg-color);
}

.LevelNav.open p {
    transition: 2s;
    color: var(--header-bg-color);
}

