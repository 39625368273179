@import url(https://fonts.googleapis.com/css?family=Fredoka+One);
@import url(https://fonts.googleapis.com/css?family=Lato:400,700,900);
@import url(https://fonts.googleapis.com/css2?family=Source+Code+Pro&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Fredoka One', 'Lato',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
    --main-bg-color: #EAD87A;
    --header-bg-color: #8EB9D8;
    --menu-bg-color: #36251D;
    --console-bg-color: #E5AA44;
}

.App {
  text-align: left;
  min-height: 100vh;
  background-color: #EAD87A;
  background-color: var(--main-bg-color);
  position: relative;
  overflow: hidden;
}
.LevelNav {
    width: 200px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to left, var(--menu-bg-color) 50%, var(--header-bg-color) 50%) left bottom;
    background-size: 200% 100%;
    transition: all 0.3s ease-out;
}

.LevelNav p:hover {
    cursor: pointer;
}

.LevelNav a {
    color: var(--menu-bg-color);
    margin-top: 15px;
    font-size: 50px;
}

.LevelNav.open {
    transition: 1s;
    background-position: right bottom;
}
.LevelNav.open a {
    transition: 2s;
    color: var(--header-bg-color);
}

.LevelNav.open p {
    transition: 2s;
    color: var(--header-bg-color);
}


html {
    height: 100%
}
body {
    margin: 0;
    padding: 0;
    height: 100%;
}
.side-drawer {
    background-color: var(--menu-bg-color);
    width: 200px;
    height: calc(100vh - 70px);
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    right: -200px;
    transition: right 0.3s ease-out;
    z-index: 1;
    overflow-y: scroll;
}

.side-drawer a {
    font-size: 28px;
    font-family: "Lato";
    color: var(--header-bg-color);
}
.side-drawer.open {
    transition: right 1s;
    right: 0px;
}
.tab-container {
  background-color: #e5aa44;
  height: 100%;
}

.button-container {
  height: 5%;
  background-color: #36251d;
  display: flex;
  justify-content: flex-start;
  padding-left: 5%;
}

.active-button {
  background-color: #e5aa44;
  font-size: 20px;
  font-family: "Lato";
  border: none;
  width: 20%;
  cursor: pointer;
  margin: 0 5px;
  padding: 0 10px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.unactive-button {
  background-color: #ead87a;
  font-size: 20px;
  font-family: "Lato";
  border: none;
  width: 20%;
  cursor: pointer;
  margin: 0 5px;
  padding: 0 10px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.HTML-text {
  font-family: "Source Code Pro", monospace;
  font-size: 15px;
}

.children {
    padding-left: 5%;
}

.container {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    text-align: left;
    position: relative;
    margin: 2em 30px;
    border: 1px solid black;
    border-radius: 20px;
    padding: 25px;
}

.subtitle {
    font-size: 35px;
    align-items: center;
    font-family: "Fredoka One";
    color: var(--menu-bg-color);
}

.tutorial-container {
    background-color: var(--header-bg-color);
    box-sizing: border-box;
    padding: 30px 280px 80px 30px;
    white-space: break-spaces;
    width: 100%;
}

.tutorial-container .tutorial-text {
    margin-top: 20px;
}

.code-text {
    color: black;
    font-weight: bold;
}
.Header {
  display: flex;
  justify-content: space-between;
  background-color: var(--header-bg-color);
  min-width: 100%;
  flex-wrap: wrap;
}

.Title {
  display: flex;
  padding-left: 30px;
  font-size: 45px;
  align-items: center;
  font-family: "Fredoka One";
  color: var(--menu-bg-color);
}

.Header-link, .Level-sidebar {
    font-family: "Lato";
    font-size: 20px;
    font-weight: 900;
    text-decoration: underline;
    color: var(--menu-bg-color);
}

.NavButtons {
  display: flex;
}

.TutorialButton {
  display: flex;
  align-items: center;
  padding-right: 80px;
}

.columns {
  display: flex;
}

.col {
  display: flex;
  flex-direction: column;
  width: 50%;
  background-color: var(--console-bg-color);
}

.Description {
  background-color: var(--menu-bg-color);
  color: var(--header-bg-color);
  padding: 0 40px;
}

.instructions {
  font-family: "Lato";
}

.next {
  margin: 0 auto;
}

.next-message {
  display: inline-block;
  padding-right: 1em;
  font-family: "Lato";
}

.next-button, .level-tutorial-button {
    background-color: var(--menu-bg-color);
    color: var(--header-bg-color);
    border: none;
    border-radius: 5px;
    padding: 0.5em 1em;
    font-family: "Fredoka One";
    font-size: 16px;
    cursor: pointer;
}

.next-button:focus {
  background-color: var(--header-bg-color);
  color: var(--menu-bg-color);
}

